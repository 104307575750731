import SwiperCore, { EffectFade } from 'swiper';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TestimonialSliderItem, TestimonialSliderItemProps } from './TestimonialSliderItem/TestimonialSliderItem';
import styles from './TestimonialSlider.module.scss';
import 'swiper/scss';
import 'swiper/scss/effect-fade';
import classNames from 'classnames';

export interface TestimonialSliderProps {
	className?: string;
	slides?: TestimonialSliderItemProps[];
}

export const TestimonialSlider: React.FC<TestimonialSliderProps> = ({ slides }) => {
	const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
	const [allowTouchMove, setAllowTouchMove] = useState<boolean>(false);

	const handlePrevClick = () => {
		if (swiperInstance) {
			swiperInstance.slidePrev();
		}
	};

	const handleNextClick = () => {
		if (swiperInstance) {
			swiperInstance.slideNext();
		}
	};

	const handleSlideChange = () => {
		if (swiperInstance) {
			setActiveSlideIndex(swiperInstance.realIndex);
		}
	};

	const updateAllowTouchMove = () => {
		if (window.innerWidth >= 768) {
			setAllowTouchMove(false);
		} else {
			setAllowTouchMove(true);
		}
	};

	useEffect(() => {
		updateAllowTouchMove();
		window.addEventListener('resize', updateAllowTouchMove);
		return () => {
			window.removeEventListener('resize', updateAllowTouchMove);
		};
	}, []);

	return (
		<Swiper
			key={allowTouchMove.toString()}
			loop
			spaceBetween={50}
			slidesPerView={1}
			className={classNames(styles.TestimonialSlider, styles[`u-color___${slides[activeSlideIndex].bgColor}`])}
			breakpoints={{
				// when window width is >= 320px
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
			}}
			onSwiper={setSwiperInstance}
			onSlideChange={handleSlideChange}
			modules={[EffectFade]}
			effect={'fade'}
			fadeEffect={{ crossFade: true }}
			speed={800}
			autoHeight
			allowTouchMove={allowTouchMove}
		>
			{slides.map((slide, index, arr) => (
				<SwiperSlide key={index}>
					<TestimonialSliderItem
						{...slide}
						slideNumber={arr.length}
						onHandlePrevClick={handlePrevClick}
						onHandleNextClick={handleNextClick}
						isActive={index === activeSlideIndex}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};
