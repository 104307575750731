import { ColorOptions } from 'helpers/types';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import styles from './TestimonialSliderItem.module.scss';
import classNames from 'classnames';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import { Container } from 'ui/components/4-habitats/Container/Container';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';
import { Button } from 'ui/components/1-atoms/Action/Button/Button';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import LeftButtonArrowKp from 'ui/icons/icon-left-button-arrow-kp.svg';
import RightButtonArrowKp from 'ui/icons/icon-right-button-arrow-kp.svg';

export interface AuthorDetails {
	name: string;
	description: string;
	image?: string;
}

export interface TestimonialSliderItemProps {
	heading: string;
	bgColor: ColorOptions;
	author?: AuthorDetails;
	slideNumber?: number;
	onHandlePrevClick?: () => void;
	onHandleNextClick?: () => void;
	navigationPrevLabel?: string;
	navigationNextLabel?: string;
	isActive: boolean;
	globalTheme?: string;
	showAuthorImage?: boolean;
}

export const TestimonialSliderItem: React.FC<TestimonialSliderItemProps> = ({
	heading,
	author,
	bgColor,
	slideNumber,
	onHandlePrevClick,
	onHandleNextClick,
	navigationPrevLabel,
	navigationNextLabel,
	isActive,
	globalTheme,
	showAuthorImage,
}) => (
	<div
		className={classNames(
			styles.TestimonialSliderItem,
			styles[`TestimonialSliderItem___${bgColor}`],
			isActive ? styles.TestimonialSliderItem___active : '',
		)}
	>
		<Container width="Standard">
			<div className={styles.TestimonialSliderItem_content}>
				<div className={styles.TestimonialSliderItem_heading}>
					<Heading className={styles.TestimonialSliderItem_text} headingLevel="h2">
						{heading}
					</Heading>

					{slideNumber > 1 && (
						<div className={styles.TestimonialSliderItem_navigation}>
							{globalTheme === 'kogp' ? (
								<>
									<Button
										className={styles.TestimonialSliderItem_navigationPrev}
										onClick={onHandlePrevClick}
										style="noAnimation"
										aria-label={navigationPrevLabel}
									>
										<Icon size="md">
											<LeftButtonArrowKp />
										</Icon>
									</Button>
									<Button
										className={styles.TestimonialSliderItem_navigationNext}
										onClick={onHandlePrevClick}
										style="noAnimation"
										aria-label={navigationNextLabel}
									>
										<Icon size="md">
											<RightButtonArrowKp />
										</Icon>
									</Button>
								</>
							) : (
								<>
									<Button aria-label={navigationPrevLabel} onClick={onHandlePrevClick} style="arrowPrev" children={''}></Button>
									<Button aria-label={navigationNextLabel} onClick={onHandleNextClick} style="arrowNext" children={''}></Button>
								</>
							)}
						</div>
					)}
				</div>

				<div className={styles.TestimonialSliderItem_author}>
					{author && author.image && showAuthorImage && (
						<div className={styles.TestimonialSliderItem_authorImage}>
							<Picture url={author.image} altText={author.name} imageCropping="cover" sizes="100px" />
						</div>
					)}
					{author && author.name && <Paragraph className={styles.TestimonialSliderItem_authorName}>{author.name}</Paragraph>}
					{author && author.description && (
						<Paragraph className={styles.TestimonialSliderItem_authorDescription}>{author.description}</Paragraph>
					)}
				</div>
			</div>
		</Container>
	</div>
);
